import React from 'react';
import { useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import Scroll from '../../../components/Scroll';
import FL_VET_BG from '../../../assets/images/FL_VetBackground.jpg';
import CourseCard from '../../../components/CourseCard';

const FloridaVets = () => {
  const [width, setWidth] = useState(200);
  const [test, setTest] = useState('');

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });
    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  const links = [];
  const helmetMeta = [
    {
      name: 'description',
      content: 'Advance Continuing Education offers license renewal courses for Florida vets.',
    },
    {
      name: 'keywords',
      content:
        'Florida Veterinarian license renewal, Florida vet license renewal, Florida vet Mandatory ce, Florida vet courses, PDH Florida vets',
    },
  ];

  return (
    <Layout links={links} logoUrl="/FL/Veterinarians" helmetMeta={helmetMeta} helmetTitle="Florida Vet License Renewal">
      <section
        id="banner"
        style={{
          backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.6), rgba(0,0,0,0.6)),url(${FL_VET_BG})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '100% 45%',
        }}
      >
        <div className="inner">
          <h2>Florida Vet License Renewal</h2>
          <h3
            style={{
              color: '#ff6400',
              marginBottom: 15,
              textDecoration: 'underline',
            }}
          >
            INCLUDES MANDATORY TOPICS
          </h3>
          <h4 style={{ color: '#ff6400', fontSize: '1.1em' }}>1-Hour Dispensing Controlled Substances</h4>
          <h4 style={{ color: '#ff6400', fontSize: '1.1em' }}>2-Hour Laws & Rules for FL Vets</h4>
          <h4 style={{ color: '#019cff', marginBottom: 10 }}>
            Course 1 (1 hour) <s style={{ color: 'red' }}>$30</s> $20
          </h4>
          <h4 style={{ color: '#019cff', marginBottom: '2em' }}>
            Entire Program (3 hours) <s style={{ color: 'red' }}>$49</s> $40
          </h4>
          <ul className="actions special">
            <li>
              <a
                href="https://storage.googleapis.com/advancece/Veterinarians/FL%20Vet%20CE%20Program.pdf"
                target="_blank"
                className="button primary"
                style={{ backgroundColor: '#ff6400' }}
              >
                View Course Materials
              </a>
            </li>
            <li>
              <a className="button secondary" href="/FL/Veterinarians/FLVET1">
                Take Test
              </a>
            </li>
          </ul>
        </div>
        <Scroll type="id" element="getting-started">
          <a href="#getting-started" className="more">
            Learn More
          </a>
        </Scroll>
      </section>
      <section
        id="getting-started"
        className="wrapper style2 special"
        style={{
          backgroundColor: '#019cff',
          minHeight: 400,
        }}
      >
        <div className="inner">
          <header>
            <h2 style={{ color: 'white', fontSize: 20 }}>Renew Your Florida Veterinarian License with Advance!</h2>
            <p style={{ textAlign: 'left', color: 'white' }}>
              Don't let your veterinarian license expire! Here at Advance CE, we offer everything you need to seamlessly renew your Vet
              license, sharpen your knowledge, and Advance your career. We provide license renewal courses that cater to professional
              veterinarians including a 1-Hour Dispensing Controlled Substances course and a 2-Hour Laws & Rules for FL Vets course.
            </p>
          </header>
          <h2 style={{ color: 'white', marginBottom: 15 }}>GETTING STARTED</h2>
          <ol style={{ textAlign: 'left', marginBottom: 25 }}>
            <li>
              <span style={{ color: 'white', fontWeight: 'bold' }}>Select “Take Test”</span> option on the home page or in the sidebar
            </li>
            <li>
              <span style={{ color: 'white', fontWeight: 'bold' }}>Fill in the answers</span> for all courses you’d like to receive credit
              for, hit “Submit”
            </li>
            <li>
              <span style={{ color: 'white', fontWeight: 'bold' }}>Create an account</span> or sign in
            </li>
            <li>
              <span style={{ color: 'white', fontWeight: 'bold' }}>Input</span> your license and payment information, and then complete the
              evaluation
            </li>
            <li>
              <span style={{ color: 'white', fontWeight: 'bold' }}>Receive your certificate</span> instantly via email
            </li>
          </ol>
        </div>
      </section>
      <section id="one" className="wrapper style2 special" style={{ minHeight: 600 }}>
        <div
          className="inner"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <header className="major">
            <h2>Veterinarian Materials</h2>
          </header>
          <CourseCard
            title="Florida Vet License Renewal"
            price={`Course 1 (1 hour): $20 \nEntire Program (3PDH): $40`}
            href="https://storage.googleapis.com/advancece/Veterinarians/FL%20Vet%20CE%20Program.pdf"
          />
        </div>
      </section>

      <section id="cta" className="wrapper style4">
        <div className="inner">
          <header>
            <h3>Have a Question? Contact Us!</h3>
            <p>A representative will respond to you as soon as possible.</p>
          </header>
          <ul className="actions stacked">
            <li>
              <a href="/ContactUs" className="button fit primary">
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </section>
    </Layout>
  );
};

export default FloridaVets;
